import React, { useState, useEffect } from 'react';
import ReviewedActivity from './ActivityTypes/ReviewedActivity';
import EndorsedActivity from './ActivityTypes/EndorsedActivity';
import FollowedBusiness from './ActivityTypes/FollowedBusiness';
import LikedDislikedActivity from './ActivityTypes/LikedDislikedActivity';
import { useSelector } from 'react-redux';
import { Box, Pagination, Typography, Button, CircularProgress, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ActivityTypeActionMapper = (activity) => {
  if (activity && activity.type === 'providers') {
    if (activity.action === 'reviewed') return 'providerReviewed';
    if (activity.action === 'endorsement') return 'providerendorsement';
    if (activity.action === 'followedBusiness') return 'providerFollowedBusiness';
    if (activity.action === 'likedReview') return 'providerLikedReview';
  }
  return null;
};

const ActivitySection = ({ activity }) => {
  const userId = useSelector((state) => state.auth.userId);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const itemsPerPage = 10; // Set the number of items per page here
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log('activity', activity)



  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleAddToNetwork = () => {
    navigate('/mynetwork');
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;


  if (error) {
    return (
      <Box sx={{ textAlign: 'center', p: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 0 }}>
      {/* Render prompt if no activity */}
      {(!activity || activity.length === 0) && (
        <Box sx={{ textAlign: 'center', p: 4 }}>
          <Typography variant="h6" gutterBottom>
            No recent activity found
          </Typography>
          <Typography variant="body1" gutterBottom>
            Connect with more people to see their activities here!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2, borderRadius: '50px' }}
            onClick={handleAddToNetwork}
          >
            Add People to Your Network
          </Button>
        </Box>
      )}

      {/* Render activities if available */}
      {activity && activity.length > 0 && (
        <Box>
          {activity.slice(startIndex, endIndex).map((activityItem) => (
            <div key={activityItem._id || Math.random()}>
              {/* Conditional rendering based on actionType */}
              {ActivityTypeActionMapper(activityItem) === 'providerReviewed' && (
                <ReviewedActivity activity={activityItem} />
              )}
              {ActivityTypeActionMapper(activityItem) === 'providerendorsement' && (
                <EndorsedActivity activity={activityItem} />
              )}
              {ActivityTypeActionMapper(activityItem) === 'providerFollowedBusiness' && (
                <FollowedBusiness activity={activityItem} />
              )}
              {ActivityTypeActionMapper(activityItem) === 'providerLikedReview' && (
                <LikedDislikedActivity activity={activityItem} />
              )}
            </div>
          ))}
        </Box>
      )}

      {/* Pagination */}
      {activity && activity.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <Pagination
            count={Math.ceil(activity.length / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
          />
        </Box>
      )}
    </Box>
  );
};

export default ActivitySection;