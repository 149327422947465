import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NoBusinessPrompt = () => {
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="400px">
      <Typography variant="h6" gutterBottom>
        You do not have an existing business.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please create a new business to get started.
      </Typography>
      <Button variant="contained" color="primary" onClick={() => navigate('/company/setup/new')}>
        Create New Business
      </Button>
    </Box>
  );
};

export default NoBusinessPrompt;
