import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useCreateSubscriptionMutation } from '../../redux/thunks/sp/createsubscription';
import { Box, Button, CircularProgress, Typography, TextField, Paper } from '@mui/material';

const stripePromise = loadStripe('pk_test_51QJTkKPhPH7xdLiZEXYNLfIHYULusajhYB1Yuwg9ZcUNrnneeHyoQylijR7E57sZMlzdugYbvKArr0gTeBRNjh3C007POlhPR9');

const CheckoutForm = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [status, setStatus] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setStatus("Processing payment...");
    console.log(`${window.location.origin}/subscription-success`);


    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `http://localhost:3000/subscription-success`
      },
    });

    if (error) {
      console.error("Payment failed:", error.message);
      setStatus(`Payment failed: ${error.message}`);
    } else {
      setStatus("Subscription created successfully!");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Address"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        fullWidth
        margin="normal"
      />
      <PaymentElement />
      <Button 
        type="submit" 
        variant="contained" 
        color="primary" 
        disabled={!stripe || status === "Processing payment..."}
        sx={{ mt: 3 }}
      >
        {status === "Subscription created successfully!" ? "Subscribed" : "Subscribe"}
      </Button>
      {status && <Typography sx={{ mt: 2 }}>{status}</Typography>}
    </form>
  );
};

const StripeCheckout = ({plan}) => {
  console.log('plan', plan)
  const [clientSecret, setClientSecret] = useState('');
  const userEmail = useSelector((state) => state.auth.user.email);
  const userToken = useSelector((state) => state.auth.token);
  const userId = useSelector((state) => state.auth.user._id);
  const displayName = useSelector((state) => state.auth.user.displayName);


  const [createSubscription, { isLoading, isError, error }] = useCreateSubscriptionMutation();

  useEffect(() => {
    const fetchClientSecret = async () => {
      const payload = {
        auth: { token: userToken, authType: 'token' },
        userId,
        userEmail,
        plan: plan.priceName,
        displayName
      };
      try {
        const response = await createSubscription({ payload }).unwrap();
        setClientSecret(response.clientSecret); // Ensure your backend returns clientSecret in response
      } catch (error) {
        console.error("Error fetching clientSecret:", error);
      }
    };

    fetchClientSecret();
  }, [userEmail, userToken, userId, createSubscription]);

  const stripeOptions = {
    clientSecret, // Pass the clientSecret to Elements options
    appearance: {
      theme: 'stripe',
    },
  };

  return clientSecret ? (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <CheckoutForm clientSecret={clientSecret} />
    </Elements>
  ) : (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <CircularProgress />
    </Box>
  );
};

export default StripeCheckout;
