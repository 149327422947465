import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

const RouteGuard = ({ children, redirectTo }) => {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  console.log('authstatus in route guard:', authStatus);

  if (authStatus === 'configuring') {
    // You can return a loading spinner or nothing until the authStatus is resolved
    return <div>Loading...</div>;
  }

  return authStatus === 'authenticated' ? children : <Navigate to={redirectTo} />;
};

export default RouteGuard;
