import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Box, Paper, CircularProgress } from '@mui/material';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import BusinessInfo from './BusinessInfo';
import BusinessLinks from './BusinessLinks';
import ActionableInsights from './ActionableInsights';
import ComparativeAnalysis from './ComparativeAnalysis';
import KPIs from './KPIs';
import SentimentAnalysis from './SentimentAnalysis';
import ReviewAnalysis from './ReviewAnalysis';
import { useGetBusinessStatsQuery  } from '../../redux/thunks/sp/getbusinessstats';

const BusinessAdminPage = () => {
  const location = useLocation();
  console.log('in provider profile, location.state', location.state);  
  const token = useSelector((state) => state.auth.token) 

  const [activeContent, setActiveContent] = useState('kpis');
  const [businessId, setBusinessId] = useState(location.state.businessId);
  const [businessStatsData, setBusinessStatsData] = useState(''); 
  const args = {
    
    authType: 'token',
    token: token,
    businessId: businessId
  }
  const { data: businessStats, error: statsError, isLoading: statsLoading } = useGetBusinessStatsQuery(args);

  useEffect(() => {   
    if(location.state.businessId) {
        setBusinessId(location.state.businessId)
    }    
  }, [location.state]);

  useEffect(() => {   
    console.log('businessStats', businessStats)
    if(businessStats) {
        
        setBusinessStatsData(businessStats)

    }    
  }, [businessStats]);

  console.log('businessStatsData', businessStatsData)


  


  const renderContent = () => {
    switch (activeContent) {
      case 'kpis':
        return <KPIs data={businessStatsData} />;
      case 'sentiment':
        return <SentimentAnalysis data={businessStatsData} />;
      case 'reviews':
        return <ReviewAnalysis data={businessStatsData} />;
      case 'comparison':
        return <ComparativeAnalysis data={businessStatsData} />;
      case 'engagement':
        return <ActionableInsights data={businessStatsData}/>;
      default:
        return <KPIs data={businessStatsData}/>;
    }
  };

  if (statsLoading ) {
    // Show a loading spinner if either user data or activities data is loading
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress color="primary" />
      </Box>

    )
  }

  return (
    <Box maxWidth={1280} mx="auto" sx={{ flexGrow: 1, p: 2 }}>
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={12} sm={4} md={3}>
          <BusinessInfo />
          <BusinessLinks setActiveContent={setActiveContent} />
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} sm={8} md={9}>
          <Paper variant="outlined" sx={{ padding: '0px', border: 'none', mb:1, borderRadius: 2, bgcolor: '#F8F9F9'  }}>
            {renderContent()}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessAdminPage;
