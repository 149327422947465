import React from 'react';
import {
  Box,
  Chip,
  Typography,
  Avatar,
  Rating,
  Grid,
  Divider,
} from '@mui/material';
import { Chart } from 'react-google-charts';
import StarIcon from '@mui/icons-material/Star';
import VerifiedIcon from '@mui/icons-material/Verified';
import InsightsIcon from '@mui/icons-material/Insights';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const SkillStats = ({ skill }) => {
  if (!skill) {
    return null; // Return null if skill or spId is undefined
  }
  // Hard-coded stats for each category
  console.log('in skill stats, skill', skill);  
  

  const interactionStats = {
    reviewsShared: 50,
    responseTime: '2 hours',
    feedbackGiven: 75,
  };
  const achievementsBadges = [
    
    'Just Started'
  ];

  const notConnectedCount = skill.count_hops_traditional[0];
  const connectedCount = skill.count_hops_traditional.slice(1).reduce((acc, value) => acc + value, 0);

  const data = [
    ['Connection Type', 'Count'],
    ['Not Connected', notConnectedCount],
    ['Connected', connectedCount],
  ];

  const options = {
    title: 'Connected Reviews',
    chartArea: { width: '80%', left: '15%', right: '10%' },
    hAxis: {
      title: 'Connection Type',
      minValue: 0,
    },
    vAxis: {
      title: 'Number of reviews',
      format: '0', // Ensure only integer values are displayed
    },
    legend: { position: 'none' },
    colors: ['#8884d8'],
  };

  return (
    <div>
      {/* Category 1: Review Contributions */}
      <Box sx={{ mb: 2, textAlign: 'left' }}>
        <Typography variant="caption">About</Typography>
        
        <Grid container alignItems="center" mb={1} mt={1}>      
          <StarIcon color="primary" sx={{ fontSize: 20, mr:1 }} />
          <Typography variant="body2" fontWeight="bold">
            Reviews: {skill.count_all}
          </Typography>
        </Grid>
        <Grid container alignItems="center" mb={1}>
          <VerifiedIcon color="primary" sx={{ fontSize: 20, mr:1 }} />
          
          <Typography variant="body2" fontWeight="bold">
            Endorsements: {skill.endorsements.length}
          </Typography>
        </Grid>
        <Grid container alignItems="center" mb={1}>
          <InsightsIcon color="primary" sx={{ fontSize: 20, mr:1 }}  />
          <Typography variant="body2" fontWeight="bold">
            Followed: {skill.follows.length}
          </Typography>
        </Grid>
        <Grid container alignItems="center" mb={1}>
        <VisibilityIcon color="primary" sx={{ fontSize: 20, mr:1 }}  />
          <Typography variant="body2" fontWeight="bold">
            Views: {interactionStats.reviewsShared}
          </Typography>
        </Grid>
      </Box>
      <Divider sx={{ mb: 1 }} />

      {/* Category 2: Network Engagement */}
      <Box sx={{ mb: 2, textAlign: 'left' }}>
        <Typography variant="caption">Connected Reviews</Typography>
        <div style={{ width: '100%', height: '300px' }}>
          <Chart
            chartType="ColumnChart"
            width="100%"
            height="300px"
            data={data}
            options={options}
          />
        </div>
      </Box>
      <Divider sx={{ mb: 1 }} />

    

      {/* Category 4: Expertise and Recognition */}
      <Box sx={{ mb: 2, textAlign: 'left' }}>
        <Typography variant="caption">Badges</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {achievementsBadges.map((category, index) => (
            <Chip
              key={index}
              label={category}
              color="primary"
              variant="outlined"
              sx={{ mr: 1, mb: 1 }}
            />
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default SkillStats;
