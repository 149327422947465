import React, {useEffect,useState} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";


import UserInfoSection from './UserInfoSection';
import MostReviewedBusinesses from './MostReviewedBusinesses';
import TopCategories from './TopCategories'

import CategoriesSection from './CategoriesSection';
import ActivitySection from './ActivitySection';
import SearchComponent from "../../Components/SearchComponent/SearchComponent";

import Footer from "../../Components/Footer/Footer";
import ProviderCard from "../../Components/ByteSize/Find/Providers/ProviderCard";
import PhotoCard from "../../Components/ByteSize/PhotoCards/PhotoCard";
import TextCard from "../../Components/ByteSize/TextCards/TextCard";
import CreatePostOptions from "../../Components/CreatePostOptions/CreatePostOptions";





import { useSpFindMutation  } from '../../redux/thunks/sp/spfind';
import { useUrlRequestSearchMutation  } from '../../redux/thunks/urls/urlrequestsearch';
import { useSpFindBusinessNameMutation } from '../../redux/thunks/sp/spfindbusinessname';
import { useSpFindRestaurantsMutation } from '../../redux/thunks/sp/spfindrestaurants'
import { useGetUserProfileQuery  } from '../../redux/thunks/users/userprofile';
import {useGetStatsGlobalQuery} from '../../redux/thunks/stats/statsglobal'
import {useGetActivitiesQuery} from '../../redux/thunks/users/getactivities'

import { usePrefetch as useGetUserIncomingReqPrefetch } from '../../redux/thunks/users/userincomingrequests';
import { usePrefetch as useGetUserOutgoingReqPrefetch } from '../../redux/thunks/users/useroutgoingrequests';

import { usePrefetch as useGetAllSkillsPrefetch } from '../../redux/thunks/things/thingsget';




import { Typography } from '@mui/material';


const UserDashboard3 = (props) => {

  const prefetchGetIncomingReq = useGetUserIncomingReqPrefetch('getUserIncomingReq')
  const prefetchGetOutgoingReq = useGetUserOutgoingReqPrefetch('getUserOutgoingReq')

  const navigate = useNavigate();
  
  const token = useSelector((state) => state.auth.token) 
  const userId = useSelector((state) => state.auth.userId) 
  const spId = useSelector((state) => state.auth.spId);  
  const userData = useSelector((state) => state.auth.user);  

  const [searchParams, setSearchParams] = useState(''); // State to manage selected tab
  const [networkActivity, setNetworkActivity] = useState(''); // State to manage selected tab

  console.log('in dashboard3 token', token)
  console.log('in dashboard3 useSelector((state) => state.auth) ', useSelector((state) => state.auth) )


  const prefetchGetAllSkills = useGetAllSkillsPrefetch('getAllThings') 


  const [triggerSearch, { data: searchResults, isLoading: isSearchLoading }] = useSpFindMutation(); 
  const [triggerSearchUrl, { data: searchResultsUrl, isLoading: isUrlSearchLoading }] = useUrlRequestSearchMutation(); 
  const [triggerSearchBusinessName, { data: searchResultsBusinessName, isLoading: isBusinessNameLoading }] = useSpFindBusinessNameMutation();
  const [triggerSearchRestaurants, { data: searchResultsRestaurants, isLoading: isRestaurantsLoading }] = useSpFindRestaurantsMutation();
  //const { data: userData, isError, isLoading: isUserLoading, error } = useGetUserProfileQuery(userId);
  const { data: statsData, isError: statsError, isLoading: isStatsLoading } = useGetStatsGlobalQuery();

  const activityArgs = { userId: userData._id, activityType: 'providers' }
  const { data: netActivity, isError, isLoading: isActivityLoading, error } = useGetActivitiesQuery(activityArgs);

  console.log('userData', userData)
  console.log('networkActivity', networkActivity)


  useEffect(() => {
    
    console.log('in user dashboard3, spId', spId)
    const prefetchAll = async () => {
      const args = {
        spId: spId,
        authType: 'token',
        token: token
      }

      await Promise.all([
        prefetchGetAllSkills(args),
        prefetchGetIncomingReq(userId),
        prefetchGetOutgoingReq(userId),
       
        
      ]);
    
    }

    prefetchAll();
   
    

  }, []);


  useEffect(() => {
    if (searchResults) {
      navigate(`/search/providers/results`, { state: { searchResults, searchParams } });
    }
  }, [searchResults, navigate]);

  useEffect(() => {
    if (netActivity) {
      setNetworkActivity(netActivity)
      
    }
  }, [netActivity]);

  useEffect(() => {
    if (searchResultsUrl) {
      navigate(`/search/urls/results`, { state: { searchResultsUrl, searchParams } });
    }
  }, [searchResultsUrl, navigate]);
  useEffect(() => {
    if (searchResultsBusinessName) {
      navigate(`/search/businessname/results`, { state: { searchResultsBusinessName, searchParams } });
    }
  }, [searchResultsBusinessName, navigate]);

  useEffect(() => {
    if (searchResultsRestaurants) {
      navigate(`/search/restaurants/results`, { state: { searchResultsRestaurants, searchParams } });
    }
  }, [searchResultsRestaurants, navigate]);

  const  handleSearch =  async (searchText, tabValue, inputAddress, zipcode, latitude, longitude ) => {
    // Handle the search with searchText and tabValue
    console.log('Search Text:', searchText);
    console.log('tabValue:', tabValue);
    console.log('inputAddress:', inputAddress);
    console.log('zipcode:', zipcode);
    console.log('latitude:', latitude);
    console.log('longitude:', longitude);

    setSearchParams({searchText, inputAddress })
    let auth = {      
      token:token,
      authType: 'token'
    }
    const payload = {
      auth,
      text: searchText
    };
    if(tabValue === 'Categories') {
      await triggerSearch({payload});

    }
    /*
    if(tabValue === 'Products') {
      await triggerSearchUrl({payload});

    }
    */
    if(tabValue === 'BusinessName') {
      await triggerSearchBusinessName({payload});

    }
    if(tabValue === 'Restaurants') {
      await triggerSearchRestaurants({payload});

    }
    
    
    
  };

  if (isSearchLoading || isUrlSearchLoading || isBusinessNameLoading || isRestaurantsLoading || isStatsLoading || isActivityLoading) {
    // Show a loading spinner if either user data or activities data is loading
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px" sx={{bgcolor: '#FFFFFF' }} >
        <CircularProgress color="primary" />
      </Box>

    )
  }


  

  return (
    <Box maxWidth={1280} mx="auto" p={2}> {/* Adjust the max width as needed */}
      {/* Full-width row with border */}
    
      
      <Paper variant="outlined" sx={{ marginBottom: '10px',  border: 'none', borderRadius: 2 }}>
          
        <SearchComponent onSearch={handleSearch} />
        
      </Paper>

      

      
     

      {/* Three columns */}
      <Grid container spacing={1}>
        {/* First column */}
        <Grid item xs={12} sm={4} md={3}>
          <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb:1, borderRadius: 2 }}>
            <UserInfoSection />
          </Paper>

          <Paper variant="outlined" sx={{ padding: '10px', border: 'none' , mb:1, borderRadius: 2 }}>
            <MostReviewedBusinesses businesses={statsData[1]} />
          </Paper>
          <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb:1, borderRadius: 2  }}>
            <TopCategories categories={statsData[0]}/>
          </Paper>

          
          
        </Grid>

        {/* Second column (50% width) on small screens, 70% on larger screens */}
        <Grid item xs={12} sm={6} md={6}>
         
          <Paper variant="outlined" sx={{  border: 'none', bgcolor: '#F8F9F9'  }}>
           
            <ActivitySection activity={networkActivity} />
          </Paper>
        </Grid>

        {/* Third column (25% width) on small screens, 15% on larger screens */}
        <Grid item xs={12} sm={4} md={3}>
          <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb:1, borderRadius: 2 }}>
            <TextCard type={'BuildNetwork'} />
          </Paper>
          
          <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb:1, borderRadius: 2 }}>
            <PhotoCard type={'StartBusiness'} />
          </Paper>
          <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb:1, borderRadius: 2, bgcolor: '#F8F9F9' }}>
            <Footer/>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDashboard3;
