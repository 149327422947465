import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, Button, Select, MenuItem, FormControl, InputLabel, CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PlaceIcon from '@mui/icons-material/Place';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';


import ResultsSection from '../ResultsSection';


import PhotoCard from "../../../Components/ByteSize/PhotoCards/PhotoCard";
import TextCard from "../../../Components/ByteSize/TextCards/TextCard"

import Footer from "../../../Components/Footer/Footer";


import FiltersSection from '../FiltersSection';
import SortSection from '../SortSection';
import GoogleMap from '../GoogleMapComponent'; 

import { usePrefetch as useGetUserCacheDataPrefetch } from '../../../redux/thunks/users/usercachedata';
import { useLazyGetUserCacheDataQuery } from '../../../redux/thunks/users/usercachedata';

/*
Logic notes: Every type of search will have its own primary landing page and then also support other type of searches. 
Search pages, use useeffect for each search then passes latest results to filters and the from filtered results to pagination and sort.
Think of the way to make a component that includes all this functionality and we dont have to repaet it everwhere. 

*/



const BusinessNameSerachResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);

  
  const searchResultsTmp = location.state?.searchResultsBusinessName || [];
  const cacheId = searchResultsTmp.cacheId;
  const searchResults = searchResultsTmp?.results || []
  const searchParams = location.state?.searchParams || [];

  

  const prefetchGetUserCacheData = useGetUserCacheDataPrefetch('getUserCacheData')
  const [getCacheData, {data: resultsCacheData, isLoading: cacheLoading}] = useLazyGetUserCacheDataQuery();

  console.log('in business name search location.state', location.state)


  console.log('in business name search results', searchResults)
  console.log('in business name cacheId', cacheId)
  const pageSize = 5;

  const [currentPage, setCurrentPage] = useState(1);
  const [filteredResults, setFilteredResults] = useState(searchResults.slice(0, pageSize));
  const [pagifiedResults, setPagifiedResults] = useState(searchResults.slice(0, pageSize));
  const [latestResults, setLatestResults] = useState(searchResults);

  const [showMap, setShowMap] = useState(false); // State to control map visibility
  const [selectedBusinesses, setSelectedBusinesses] = useState([]); // State to store selected businesses for map
  const [showCachedResults, setShowCachedResults] = useState(false);   // New state to toggle between showing current and cached results
  const [fetchResultsTrigger, setFetchResultsTrigger] = useState(false); // New trigger state to make sure network discovery is called seconf time

  const handleDiscoverFromNetwork = () => {

    getCacheData(cacheId)
    setFetchResultsTrigger(!fetchResultsTrigger); // Toggle the trigger

    
  };

  

  useEffect(() => {
    if (cacheId) {    
      prefetchGetUserCacheData(cacheId)      
    }
  }, [cacheId]);

  useEffect(() => {
    if (filteredResults) {      
      setPagifiedResults(filteredResults.slice(0, pageSize));
      console.log('filteredResults in useeffect', filteredResults)
    }
  }, [filteredResults]);

  useEffect(() => {
    if (resultsCacheData) {
      console.log('resultsCacheData', resultsCacheData)
      const uniqueObjectIdsArray = resultsCacheData.uniqueObjectIdsArray || [];
      console.log('uniqueObjectIdsArray', uniqueObjectIdsArray)
  
      // Convert ObjectIDs to strings
      const stringObjectIds = uniqueObjectIdsArray.map((objectId) => objectId.businessId.toString());
      console.log('stringObjectIds', stringObjectIds)
  
      // Filter the latestResults array based on stringObjectIds
      const filteredLatestResults = latestResults.filter((business) =>
        stringObjectIds.includes(business._id)
      );
      console.log('filteredLatestResults', filteredLatestResults)
  
      setLatestResults(filteredLatestResults);
      setPagifiedResults(filteredLatestResults.slice(0, pageSize));
      setFilteredResults(filteredLatestResults)
      setShowCachedResults(true); // Show cached results when data is available

      //Also prefetch all the users from my network that are part of frommynetwork operation

    }
  }, [resultsCacheData, fetchResultsTrigger]);

  const handleViewOnMapClick = () => {
    setShowMap(true);
    setSelectedBusinesses(pagifiedResults); // Pass the pagifiedResults array to setSelectedBusinesses
  };

  


  const handlePageChange = (newPage) => {
    const startIndex = (newPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setCurrentPage(newPage);
    setPagifiedResults(filteredResults.slice(startIndex, endIndex));
  }; 

  const handleBackToResults = () => {
    setFilteredResults(searchResults.slice(0, pageSize));
    setPagifiedResults(searchResults.slice(0, pageSize));
    setShowCachedResults(false);
  };
  

  
  return (
    <Box maxWidth={1280} mx="auto" p={2} >
      {/* First Full-Width Row with Border */}
      <Paper variant="outlined" style={{ marginBottom: '0px', padding: '0px', border: 'none' }}>
       
      </Paper>

       {/* Display Google Map when showMap is true */}
       {showMap && selectedBusinesses.length > 0 && (
        <GoogleMap
        businesses={selectedBusinesses}
          onClose={() => {
            setShowMap(false);
            setSelectedBusinesses([]);
          }}
        />
      )}

     
     


      
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4} md={2}>
          {/* First Column (30%) */}
          <Paper variant="outlined" sx={{ padding: '10px', border: 'none', borderRadius: 2  }}>
            <FiltersSection results={latestResults} setFilteredResults={setFilteredResults} filter={'generic'} />
            
          </Paper>
        </Grid>
        <Grid item xs={12} sm={8} md={7}>
          {/* Second Column (70%) */}
          <Paper variant="outlined" sx={{ padding: '0px', border: 'none', bgcolor: '#F8F9F9' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ bgcolor: 'white', padding: '15px', border: '1px solid rgba(0, 0, 0, 0.1)', mb:1, mt:0, borderRadius: '30px'  }}>

              
              
              
                <Typography variant="body2" color="textSecondary" style={{ marginBottom: '8px' }}>
                  Showing {currentPage * pageSize - pageSize + 1} - {Math.min(currentPage * pageSize, filteredResults.length)} of {filteredResults.length}
                </Typography>

                <Button variant="outlined" startIcon={<PlaceIcon />} color="primary" onClick={handleViewOnMapClick}> Show on Map</Button>

                {showCachedResults ? 
                <Button variant="outlined" startIcon={<ArrowLeftIcon />} color="primary" onClick={handleBackToResults}>Back</Button> 
                :                 
                <Button variant="outlined" startIcon={<Diversity3Icon />} color="primary" onClick={handleDiscoverFromNetwork}>Insiders</Button>                
                }


                {filteredResults.length > 0 && (
                  <SortSection
                    results={filteredResults}
                    setFilteredResults={setFilteredResults}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    type={'provider'}
                  />
                )}
                
             
            </Box>
            <Typography variant="subtitle2" fontWeight="bold" my={2}>
                Searching for {searchParams.searchText} in {searchParams.inputAddress} 
            </Typography>
                        
            {filteredResults.length > 0 ? (
              <ResultsSection searchResults={pagifiedResults} type={'provider'} cacheId={cacheId} />
            ) : (
              <Typography variant="h6" color="textSecondary" align="center" mt={3}>
                No results found
              </Typography>
            )}

            {filteredResults.length > 0 && (
              <Box mt={2}>
                <Box display="flex" justifyContent="space-between">
                  <Button
                    variant="outlined"
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={currentPage * pageSize >= filteredResults.length}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          {/* First Column (30%) */}
          <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb:1, borderRadius: 2 }}>
            <TextCard type={'SeekBusinessReviews'} />     
          </Paper>
          <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb:1, borderRadius: 2, bgcolor: '#F8F9F9'  }}>
            <Footer/>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessNameSerachResults;
