import React, { useState } from 'react';
import moment from 'moment'; // Import moment
import {
  Box,
  Paper,
  Typography,
  Avatar,
  Rating,
  Grid,
  IconButton,
  Badge, // Import Badge
} from '@mui/material';
import { MdThumbUp, MdThumbDown, MdEdit, MdDelete, MdComment } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useSpReviewLikeMutation } from '../../redux/thunks/sp/spreviewlike';

const ReviewDetails = ({ review, onCommentsClick }) => {
  console.log('Review', review);
  
  const navigate = useNavigate();
  const comments = review.comments || [];

  const token = useSelector((state) => state.auth.token);
  const reviewLiked = useSelector((state) => state.auth.user.reviewsLiked) || [];
  const reviewDisliked = useSelector((state) => state.auth.user.reviewsDisliked) || [];

  const [thumbsUpPressed, setThumbsUpPressed] = useState(reviewLiked.includes(review._id));
  const [thumbsDownPressed, setThumbsDownPressed] = useState(reviewDisliked.includes(review._id));
  const [likeReview, { isLoading: likeDislikeReviewLoading }] = useSpReviewLikeMutation();

  const handleCommentsClick = () => {
    navigate(`/review/comments/${review._id}`, { state: review });
  };

  const handleLikeAction = async (actionType) => {
    const payload = {
      auth: {
        token: token,
        authType: 'token'
      },
      reviewId: review._id,
      action: actionType
    };

    if (actionType === 'like') {
      setThumbsUpPressed(true);
      setThumbsDownPressed(false);
    } else if (actionType === 'dislike') {
      setThumbsDownPressed(true);
      setThumbsUpPressed(false);
    } else if (actionType === 'unlike') {
      setThumbsUpPressed(false);
    } else if (actionType === 'undislike') {
      setThumbsDownPressed(false);
    }

    await likeReview({ payload });
  };

  const handleThumbsUpPress = () => {
    if (thumbsUpPressed) {
      handleLikeAction('unlike');
    } else {
      handleLikeAction('like');
      if (thumbsDownPressed) {
        handleLikeAction('undislike');
      }
    }
  };

  const handleThumbsDownPress = () => {
    if (thumbsDownPressed) {
      handleLikeAction('undislike');
    } else {
      handleLikeAction('dislike');
      if (thumbsUpPressed) {
        handleLikeAction('unlike');
      }
    }
  };

  return (
    <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb: 1, borderRadius: 2 }}>
      {/* First Row */}
      <Box display="flex" alignItems="center" mb={1}>
        <Avatar src={review.userDetails.profileImageUrl} alt={review.userDetails.displayName} sx={{ width: 60, height: 60, mr: 2 }} />
        <Box>
          <Typography variant="h6">{review.userDetails.displayName}</Typography>
          <Typography variant="body2" color="textSecondary">
            {`Reviewed ${moment(review.created).fromNow()}`} {/* Display formatted date */}
          </Typography>
        </Box>
      </Box>

      {/* Star Rating */}
      <Box mb={2}>
        <Rating value={review.rating} precision={0.5} readOnly />
      </Box>

      {/* Second Row */}
      <Typography variant="body1" mb={2}>
        {review.review}
      </Typography>

      {/* Third Row: Icons */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <IconButton color={thumbsUpPressed ? 'primary' : 'default'} aria-label="Thumb Up" onClick={handleThumbsUpPress}>
            <MdThumbUp />
          </IconButton>
          <IconButton color={thumbsDownPressed ? 'error' : 'default'} aria-label="Thumb Down" onClick={handleThumbsDownPress}>
            <MdThumbDown />
          </IconButton>
          <IconButton color="primary" aria-label="Comments" onClick={handleCommentsClick}>
            <Badge badgeContent={comments.length} color="error">
              <MdComment />
            </Badge>
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ReviewDetails;
