import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import StarIcon from '@mui/icons-material/Star';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BuildIcon from '@mui/icons-material/Build';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PublicIcon from '@mui/icons-material/Public';

// Define the actionable insights areas with icons
const insightsAreas = [
  { area: 'Marketing', icon: <LocalOfferIcon sx={{ fontSize: 30, marginRight: 2 }} /> },
  { area: 'Customer Experience', icon: <EmojiPeopleIcon sx={{ fontSize: 30, marginRight: 2 }} /> },
  { area: 'Product Development', icon: <RestaurantMenuIcon sx={{ fontSize: 30, marginRight: 2 }} /> },
  { area: 'Competition', icon: <StarIcon sx={{ fontSize: 30, marginRight: 2 }} /> },
  { area: 'Customer Retention', icon: <LoyaltyIcon sx={{ fontSize: 30, marginRight: 2 }} /> },
  { area: 'Sales Optimization', icon: <TrendingUpIcon sx={{ fontSize: 30, marginRight: 2 }} /> },
  { area: 'Operational Efficiency', icon: <BuildIcon sx={{ fontSize: 30, marginRight: 2 }} /> },
  { area: 'Customer Acquisition', icon: <AccountBalanceIcon sx={{ fontSize: 30, marginRight: 2 }} /> },
  { area: 'Customer Service', icon: <PublicIcon sx={{ fontSize: 30, marginRight: 2 }} /> }
];

// Helper function to sanitize suggestions
const sanitizeSuggestion = (suggestion) => {
  // Remove any leading punctuations or symbols like *, -, etc.
  return suggestion.replace(/^[\*|\-|\.|,|\s]+/, '').trim();
};

const ActionableInsights = ({ data }) => {
  console.log('data', data);

  return (
    <Box sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        {insightsAreas.map((insight, index) => {
          // Fetch the suggestion from the data object based on the area name
          let suggestion = data.actionableInsightsData?.[insight.area] || 'Not enough reviews yet for the insights';

          suggestion = sanitizeSuggestion(suggestion);


          return (
            <Grid item xs={12} sm={4} key={index}>
              <Card variant="outlined" sx={{ height: '100%' , padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    {insight.icon}
                    <Typography variant="caption">{insight.area}</Typography>
                  </Box>
                  <Typography variant="body2">{suggestion}</Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ActionableInsights;
