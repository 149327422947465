
import {spApi} from './sp'

export const createSubscriptionApi = spApi.injectEndpoints({
  endpoints: (builder) => ({
    
    createSubscription: builder.mutation({
     
      query: ({payload}) => ({
        url: '/createsubscription',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: false,
})

export const { useCreateSubscriptionMutation } = createSubscriptionApi