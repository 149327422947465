import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@mui/material';
import { Formik, Field, Form } from 'formik';
import { useGetUserProfileQuery, useUpdateUserProfileMutation } from '../../redux/thunks/users/userprofile';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'; // Import react-places-autocomplete components

const EditProfileDialog = ({ open, onClose }) => {
  const navigate = useNavigate();
  const userId = useSelector((state) => state.auth.user._id);
  const { data: userData } = useGetUserProfileQuery(userId);
  const [editProfile, { isLoading: ProfileEditSaving }] = useUpdateUserProfileMutation();

  const initialValues = {
    firstName: userData?.firstName || '',
    lastName: userData?.lastName || '',
    address: userData?.address || '',
    dateOfBirth: userData?.dateOfBirth || new Date(),
    phoneNumber1: userData?.phoneNumber1 || '',
  };

  const handleSubmit = async (values) => {
    console.log(' in handle submit formData', values);
    console.log(' in handle submit dateOfBirth', values.dateOfBirth.toISOString());
    const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        address: values.address,
        phoneNumber1: values.phoneNumber1,
        dateOfBirth: values.dateOfBirth.toISOString(),
        
    };
    await editProfile({userId, payload});
    navigate(`/profile/${userId}`);
    onClose();
  };

  // State to manage the address input value
  const [address, setAddress] = useState('');

  // Function to handle address input change
  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };

  // Function to handle address selection
  const handleSelect = async (selectedAddress) => {
    setAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      console.log('Selected address:', selectedAddress);
      console.log('Latitude and Longitude:', latLng);
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Profile</DialogTitle>
      <DialogContent>
        <DialogContentText>Edit your profile information below:</DialogContentText>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (  
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  name="firstName"
                  label="First Name"
                  fullWidth
                  variant="outlined"
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="lastName"
                  label="Last Name"
                  fullWidth
                  variant="outlined"
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                {/* Integrate the PlacesAutocomplete component */}
                <PlacesAutocomplete
                  value={address}
                  onChange={handleAddressChange}
                  onSelect={handleSelect}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <TextField
                        name="address"
                        label="Address"
                        fullWidth
                        variant="outlined"
                        {...getInputProps({
                          placeholder: 'Enter your address',
                        })}
                      />
                      <div>
                        {loading ? <div>Loading...</div> : null}
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                          };
                          return (
                            <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                              {suggestion.description}
                              
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="dateOfBirth"
                  label="Date of Birth (Optional)"
                  fullWidth
                  variant="outlined"
                  as={DatePicker}
                  format="MM/DD/YYYY"
                  value={moment(values.dateOfBirth)}
                  onChange={(date) => setFieldValue('dateOfBirth', date, true)}
                  slotProps={{ textField: { variant: 'outlined' } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="phoneNumber1"
                  label="Phone"
                  fullWidth
                  variant="outlined"
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="_id"
                  label="User ID"
                  fullWidth
                  variant="outlined"
                  value={userData?._id || ''}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  label="Email"
                  fullWidth
                  variant="outlined"
                  value={userData?.email || ''}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit" color="primary" variant="contained">
                Save
              </Button>
            </DialogActions>
          </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EditProfileDialog;


//key={suggestion.placeId} added from https://github.com/hibiken/react-places-autocomplete/issues/345
