import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Button,
  Modal,
  Box,
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import StripeCheckout from './StripeCheckout'; // Import the StripeCheckout component

export default function PricingCard({ plan }) {
  const currentAccountType = useSelector((state) => state.auth.user.accountDetails.type);
  const [showCheckout, setShowCheckout] = useState(false);

  const handleFreeTrialClick = () => {
    setShowCheckout(true); // Show StripeCheckout when the button is clicked
  };

  const handleClose = () => {
    setShowCheckout(false); // Hide StripeCheckout when the modal is closed
  };

  return (
    <Card
      sx={{
        borderRadius: '10px',
        boxShadow: 'none',
        bgcolor: plan.name === 'Premium' ? 'common.white' : '#F6F8FA',
        position: 'relative',
        overflow: 'unset',
        boxShadow: plan.name === 'Premium' ? 'rgba(145, 158, 171, 0.25) 0px 6px 16px' : 'none',
        ':before': {
          content: "''",
          position: 'absolute',
          background: 'url(tag.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          height: { xs: 67, md: 97 },
          width: { xs: 62, md: 92 },
          top: -13,
          right: 0,
          transform: 'translateX(-50%)',
        },
      }}
    >
      <CardContent sx={{ p: { xs: 2, md: '56px !important' } }}>
        <Typography
          fontSize={{ xs: 18, md: plan.name === 'Free' ? 34 : 24 }}
          fontWeight={800}
          variant="h6"
          color="common.blue"
          sx={{
            position: 'absolute',
            height: { xs: 67, md: 97 },
            width: { xs: 62, md: 92 },
            top: { xs: 10, md: plan.name === 'Free' ? 5 : 10 },
            right: { xs: plan.name === 'Free' ? -5 : 0, md: plan.name === 'Free' ? 0 : -5 },
            transform: 'translateX(-30%)',
          }}
        >
          {plan.price}
        </Typography>
        <Stack spacing={0.5}>
          <Typography variant="h4" fontWeight={900} fontSize={30}>
            {plan.name}
          </Typography>
          <Typography variant="body2" fontSize={14} color="text.secondary">
            {plan.description}
          </Typography>
          <List
            sx={{
              px: 0,
              '& .MuiListItem-root': { px: 0 },
              '& .MuiTypography-root': { fontSize: 16, color: 'text.secondary' },
            }}
          >
            {plan.list.map((v) => (
              <ListItem key={Math.random()}>
                <ListItemIcon
                  sx={{
                    minWidth: 24,
                    svg: { fontSize: 8 },
                  }}
                >
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText>{v}</ListItemText>
              </ListItem>
            ))}
          </List>
         
          {plan.name === 'Premium' && (
             <Stack sx={{ pb: 4 }}>
             <Typography variant="body1" fontSize={14} color="primary">
             Best For: 
             </Typography>
             <Typography variant="body2" fontSize={14} color="text.secondary">
             Growing businesses seeking data-driven insights, competitive edge, and deeper customer connections.
             </Typography>
           </Stack>
          )}
          
          {currentAccountType === 'free' ? (
            plan.name === 'Free' ? (
              <Button
                sx={{
                  borderRadius: '10px',
                  textTransform: 'none',
                  border: '1px solid', 
                  height: 48,
                  fontWeight: 300,
                  boxShadow: 'none',
                  color: 'text.secondary',
                  cursor: 'default',
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                }}
                size="large"
                disabled
                fullWidth
              >
                Continue with Free
              </Button>
            ) : (
              <Button
                sx={{
                  borderRadius: '10px',
                  textTransform: 'none',
                  height: 48,
                  fontWeight: 300,
                  boxShadow: 'none',
                }}
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleFreeTrialClick}
              >
                Start Premium membership
              </Button>
            )
          ) : currentAccountType === 'premium' && plan.name === 'Premium' ? (
            <Typography
              variant="body2"
              color="primary"
              textAlign="center"
              sx={{ fontWeight: 500, mt: 2 }}
            >
              Thanks for being a premium member!
            </Typography>
          ) : null}
        </Stack>
      </CardContent>

      {/* Modal for Stripe Checkout */}
      <Modal
        open={showCheckout}
        onClose={handleClose}
        aria-labelledby="checkout-modal-title"
        aria-describedby="checkout-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            height: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="checkout-modal-title" variant="h6" component="h2">
            Complete Your Subscription
          </Typography>
          <StripeCheckout plan={plan}/>
        </Box>
      </Modal>
    </Card>
  );
}
