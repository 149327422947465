import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import { Group } from '@visx/group';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud';
import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';
import PeopleIcon from '@mui/icons-material/People';
import WcIcon from '@mui/icons-material/Wc';
import LanguageIcon from '@mui/icons-material/Language';
import { Chart } from 'react-google-charts';




const SentimentAnalysis = ({data}) => {

  console.log('data', data)
  const sentimentAnalysisData = data.sentimentData
  const sentimentData = {
    bestAgeGroup: '25-34',
    bestGender: 'Female',
    bestChannel: 'Online',
    sentimentProportions: {
      positive: 60,
      neutral: 25,
      negative: 15,
    },
    monthlyTrend: [
      { month: 'Jan-2024', value: 10 },
      { month: 'Feb-2024', value: 15 },
      { month: 'Mar-2024', value: 16 },
      { month: 'Apr-2024', value: 20 },
      { month: 'May-2024', value: 25 },
      { month: 'Jun-2024', value: 30 },
      { month: 'Jul-2024', value: 60 },
    ],
    mostPositiveReviews: [
      { id: 1, text: 'Fantastic service!', reviewer: 'Alice', date: '2024-07-01' },
      { id: 2, text: 'Loved the experience.', reviewer: 'Bob', date: '2024-07-05' },
      { id: 3, text: 'Exceeded my expectations.', reviewer: 'Charlie', date: '2024-07-10' },
    ],
    wordClouds: {
      positive: [
        { text: 'Amazing', value: 100 },
        { text: 'Great', value: 70 },
        { text: 'Fantastic', value: 60 },
        { text: 'Loved', value: 50 },
        { text: 'Best', value: 10 },
      ],
      negative: [
        { text: 'Bad', value: 80 },
        { text: 'Terrible', value: 70 },
        { text: 'Disappointing', value: 60 },
        { text: 'Poor', value: 50 },
        { text: 'Worst', value: 40 },
      ],
    },
    sentimentVsRating: [
      { rating: 1, positive: 10 },
      { rating: 2, positive: 20 },
      { rating: 3, positive: 30 },
      { rating: 4, positive: 40 },
      { rating: 5, positive: 50 },
    ],
  };

  const fontScale = scaleLog({
    domain: [
      Math.min(...sentimentData.wordClouds.positive.map((w) => w.value)),
      Math.max(...sentimentData.wordClouds.positive.map((w) => w.value)),
    ],
    range: [10, 30],
  });

  const fontSizeSetter = (datum) => fontScale(datum.value);
  const colors = ["#143059", "#2F6B9A", "#82a6c2"]; 


  const fixedValueGenerator = () => 0.5;

  
  // Prepare data for the Google Charts Donut
  const chartData = [
    ['Sentiment', 'Percentage'],
    ['Positive', sentimentAnalysisData.sentimentProportion.Positive],
    ['Neutral', sentimentAnalysisData.sentimentProportion.Neutral],
    ['Negative', sentimentAnalysisData.sentimentProportion.Negative],
    ['Mixed', sentimentAnalysisData.sentimentProportion.Mixed],
  ];

  const chartOptions = {
    pieHole: 0.4,
    is3D: false,
    slices: [
      { color: '#00C49F' },
      { color: '#FFBB28' },
      { color: '#FF8042' },
    ],
    legend: 'none'
  };

  // Prepare data for the Google Charts Line Graph
  const trendData = [
    ['Month', 'Overall Sentiment'],
    ...sentimentAnalysisData.monthlyTrend.map((dataPoint) => [dataPoint.month, dataPoint.value]),
  ];

  const trendOptions = {
    hAxis: {
      title: 'Month',
    },
    vAxis: {
      title: 'Overall Sentiment',
    },
    legend: 'none',
    curveType: 'function',
    pointSize: 5,
  };

  // Prepare data for the Google Charts Bar Graph
  const barData = [
    ['Rating', 'Positive Sentiments'],
    ...sentimentAnalysisData.sentimentVsRating.map((dataPoint) => [dataPoint.rating, dataPoint.positive]),
  ];

  const barOptions = {
    hAxis: {
      title: 'Rating',
      minValue: 0,
    },
    vAxis: {
      title: 'Positive Sentiments',
    },
    legend: 'none',
    colors: ['#00C49F'],
    bar: { groupWidth: '50%' },
  };




  return (
    <Box>
      {/* First Row */}
      <Grid container spacing={2} sx={{ mb: 2 }} alignItems="stretch">
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%' , padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
              <PeopleIcon sx={{ fontSize: 40, marginRight: 2 }} />
              <Box>
                <Typography variant="caption">Best Age Group</Typography>
                <Typography variant="h6">{sentimentAnalysisData.bestAgeGroup}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%' , padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
              <WcIcon sx={{ fontSize: 40, marginRight: 2 }} />
              <Box>
                <Typography variant="caption">Best Gender</Typography>
                <Typography variant="h6">{sentimentAnalysisData.bestGender}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%' , padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
              <LanguageIcon sx={{ fontSize: 40, marginRight: 2 }} />
              <Box>
                <Typography variant="caption">Best Channel</Typography>
                <Typography variant="h6">{sentimentData.bestChannel}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Second Row */}
      <Grid container spacing={2} sx={{ mb: 2 }} alignItems="stretch">
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%' , padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
            <CardContent>
              <Typography variant="caption" sx={{ textAlign: 'center', display: 'block', my: 2 }} >Sentiment Proportions</Typography>
              <Chart
                chartType="PieChart"
                data={chartData}
                options={chartOptions}
                width="100%"
                height="200px"
              />
             
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card  variant="outlined" sx={{ height: '100%' , padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
            <CardContent>
              <Typography variant="caption" sx={{ textAlign: 'center', display: 'block', my: 2 }} >Monthly Sentiment Trend</Typography>
              <Chart
                chartType="LineChart"
                data={trendData}
                options={trendOptions}
                width="100%"
                height="200px"
              />
             
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: '100%' , padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
            <CardContent >
              <Typography variant="caption" sx={{ textAlign: 'center', display: 'block', mt: 2 }} >Positive Sentiment vs Star Rating</Typography>
              <Chart
                chartType="ColumnChart"
                data={barData}
                options={barOptions}
                width="100%"
                height="200px"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Third Row */}
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} sm={6}>
          <Card  variant="outlined" sx={{ height: '100%' , padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
            <CardContent>
              <Typography variant="caption" sx={{ textAlign: 'center', display: 'block', my: 2 }} >Positive Reviews Word Cloud</Typography>
              <svg width={300} height={200}>
                <Group top={10} left={10}>
                  <Wordcloud
                    words={sentimentData.wordClouds.positive}
                    width={190}
                    height={190}
                    fontSize={fontSizeSetter}
                    padding={3}
                    spiral="rectangular"
                    rotate={() => (Math.random() > 0.5 ? 90 : 0)}
                    random={fixedValueGenerator}
                  >
                    {(cloudWords) =>{
                      console.log('cloudwords', cloudWords)

                      return cloudWords.map((w, i) => (
                        <Text
                        key={w.text}
                          fill={colors[i % colors.length]}
                          textAnchor="middle"
                          transform={`translate(${w.x }, ${w.y }) rotate(${w.rotate })`}
                          fontSize={w.size}
                        >
                          {w.text} 
                        </Text>
                      ))

                    }
                      
                    }
                  </Wordcloud>
                </Group>
              </svg>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card variant="outlined" sx={{ height: '100%' , padding: '10px', border: 'none', mb:1, borderRadius: 2}}>
            <CardContent>
              <Typography variant="caption" sx={{ textAlign: 'center', display: 'block', my: 2 }} >Negative Reviews Word Cloud</Typography>
              <svg width={300} height={200}>
                <Group top={10} left={10}>
                  <Wordcloud
                    words={sentimentData.wordClouds.negative}
                    width={190}
                    height={190}
                    fontSize={fontSizeSetter}
                    padding={3}
                    spiral="rectangular"
                    rotate={() => (Math.random() > 0.5 ? 90 : 0)}
                    random={fixedValueGenerator}
                  >
                    {(cloudWords) =>
                      cloudWords.map((w, i) => (
                        <Text
                        key={w.text}
                          fill={colors[i % colors.length]}
                          textAnchor="middle"
                          transform={`translate(${w.x }, ${w.y }) rotate(${w.rotate })`}
                          fontSize={w.size}
                        >
                          {w.text}
                        </Text>
                      ))
                    }
                  </Wordcloud>
                </Group>
              </svg>
            </CardContent>
          </Card>
        </Grid>
       
      </Grid>
    </Box>
  );
};

export default SentimentAnalysis;
